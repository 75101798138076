import React, { useState, useContext, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { mediaQueryHOC,
         Icon,
         SpotlightWaitlistConstants,
         Constants,
         ContentProperty,
         Heading,
         Button,
         Image,
         CampaignIframeOverlayConstants,
         CheckboxInput,
         mixtureGraphQL,
         mixtureFormRegister,
         filterMethod,
         checkPattern,
         interestitialEventPush,
         getCookieValue,
         OnePageScrollContext,
         updateTraitsMixtureFormRegister
        } from './exportComponent';
import { customJsonstringify } from '../../../adapters/helpers/Utils';

function InterstitialLayout(props) {

    const { document: entity, isMedium, index} = props;

    const { title,
        body,
        legalText,
        anchorId,
        closeModalLabel,
        mobileBackgroundAsset,
        backgroundAsset,
        thankYouCallToAction,
        rawTopicList,
        ctaLabel,
        formLabels,
        isImplicitTCAgree,
        isInterstitialPopup,
        countrySelectErrorMessage,
        emailExceedLimitLabel,
        thankYouTitleLabel, 
        thankYouBackgroundAsset,
        thankYouDescriptionLabel,
        expiryHours,
        modalLabelFirst,
        modalLabelSecond,
        countrySelectLabel,
        countrySelectList,
        countrySelectListDefaultOption,
        callToPreOrderActionLabel,
        countryDropdownList,
        countrySelectMention
        }  = ContentProperty(entity);   
        
    const blockRef = useRef(null);
    const firstLegalModalRef = useRef(null);

    const context = useContext(OnePageScrollContext);
    const currentIndex = context?.currentIndex || 0;
    const isActiveBlock = (index === currentIndex);

    const [interstitialModalOpen, setIsInterstitialModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userData, setUserData] = useState({});
    const [erroneousInputs, setErroneousInputs] = useState([]);
    const [serverSideErrorMessage, setServerSideErrorMessage] = useState(false);
    const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(isImplicitTCAgree);
    const [traitsData, setTraitsData] = useState();
    const [optinsData, setOptinsData] = useState();
    const [isSecondModalOpen, setIsModalOpen] = useState(false);
    const [isFirstLegalModalOpen, setisFirstLegalModalOpen] = useState(false);
    const [isSecondLegalModalOpen, setIsSecondLegalModalOpen] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [emailOptInStatus, setEmailOptInStatus] = useState(true);

    
    const [campaignOverlayPopUp, setcampaignOverlayPopUp] = useState(false);
    const [countryList, setCountryList] = useState({});
    const [isTraitRegistrationSuccess, setIsTraitRegistrationSuccess] = useState(false);
    const [isPIIDataRegistrationSuccess, setIsPIIDataRegistrationSuccess] = useState(false);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [participationID, setParticipationId] = useState();

    const isError = erroneousInputs.length > 0;

    const setLegalModals = () => {
        if(blockRef.current) {
            let firstModalId = blockRef.current.querySelector('#openFirstLegalModal');
            if (firstModalId) {
                firstModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setisFirstLegalModalOpen(true);
                })
            }

            let secondModalId = blockRef.current.querySelector('#openSecondLegalModal');
            if (secondModalId) {
                secondModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setIsSecondLegalModalOpen(true);
                })
            }
        }
    };

    const closeModal = () => {
        closeLegalModal();
        setIsInterstitialModalOpen(false);
        setIsModalOpen(false);
        sessionStorage.removeItem(CampaignIframeOverlayConstants.interestitialUserDetail);
        (isPIIDataRegistrationSuccess && !isTraitRegistrationSuccess) ? closeInterestitialEmailResponseEventPush(true) : (!isTraitRegistrationSuccess) && closeInterestitialWithoutEmailResponseEventPush(true);
    }

    const closeLegalModal = () => {
        setisFirstLegalModalOpen(false);
        setIsSecondLegalModalOpen(false);      
    };

    const closeModalCampaignOverlay = (event) => {
        event.preventDefault();
        setIsInterstitialModalOpen(false);
        (isPIIDataRegistrationSuccess && !isTraitRegistrationSuccess) ? closeInterestitialEmailResponseEventPush(true) : (!isTraitRegistrationSuccess) && closeInterestitialWithoutEmailResponseEventPush(true);
    };

    const closeInterestitialEmailResponseEventPush = (isSuccess) => {
        if(isSuccess){
            interestitialEventPush(
                CampaignIframeOverlayConstants?.eventCategory,
                CampaignIframeOverlayConstants?.completeSurveyEvent,
                CampaignIframeOverlayConstants?.eventCloseLabelWithEmailResponse
            )
        }
    };
    const closeInterestitialWithoutEmailResponseEventPush = (isSuccess) => {
        if(isSuccess){
            interestitialEventPush(
                CampaignIframeOverlayConstants?.eventCloseCategory,
                CampaignIframeOverlayConstants?.eventCloseAction,
                CampaignIframeOverlayConstants?.eventCloseLabelWithoutNoResponse
            );
        }
        setIsPIIDataRegistrationSuccess(false);
        setIsTraitRegistrationSuccess(false);
    };

    const closeInterestitialSurveyResponseEventPush = (isSuccess) => {
        if(isSuccess){
            interestitialEventPush(
                CampaignIframeOverlayConstants?.eventCategory,
                CampaignIframeOverlayConstants?.completeSurveyEvent,
                CampaignIframeOverlayConstants?.eventCloseLabelWithSurveyResponse
            );
        }
    };

    const interestitialSurveyResponseEventPush = (responseValue) => {
        if (responseValue) {
            for (i = 0; i < responseValue.length; i++) {
                window && window.dataLayer && window.dataLayer.push({
                    'event': CampaignIframeOverlayConstants?.customEvent,
                    'GAeventCategory': `${CampaignIframeOverlayConstants?.eventCategory}`,
                    'GAeventAction': `${CampaignIframeOverlayConstants?.questionSurveyAction}`,
                    'GAeventLabel': `interstitial_traitBenefitPreference_${responseValue[i]}`
                });
            }
        }
    };

    const clearErrorStyle = (id) => {
        const inputElement = document.getElementById(id);
        inputElement.classList.remove('--error');
        const updatedErrorInputs = erroneousInputs.filter(errorInput => errorInput?.id !== id);
        setErroneousInputs(updatedErrorInputs);
        setServerSideErrorMessage(false);
    }

    const getCountryById = (id) => {
        let country = {};
        let countryListData = countryList;

        for (let i = 0; i < countryListData.length; ++i) {
            let countryId = countryListData[i]?.countryId;
            if (countryId === parseInt(id)) {
                country = countryListData[i];
            }
        }

        return country;
    };

    const handleSubmit = async event => {
        event.preventDefault();
        setIsSubmitting(true);
        setIsLoader(true);
        const form = event.target;
        let termsAndConditionsChecked;
        if(isImplicitTCAgree) {
            termsAndConditionsChecked = isImplicitTCAgree;
            setHasAcceptedTermsAndConditions(isImplicitTCAgree);
        } else if(!isInterstitialPopup && !document.querySelector('#TermsAndConditions')) {
            termsAndConditionsChecked = document.querySelector('#TermsAndConditions') != null && document.querySelector('#TermsAndConditions').checked;
            setHasAcceptedTermsAndConditions(termsAndConditionsChecked);
        } else {
            termsAndConditionsChecked = hasAcceptedTermsAndConditions;
        }
        
        const inputs = form.querySelectorAll('input, select');
        const country = getCountryById(form.spotlight_country?.value);
        let errors = [];

        for(let i = 0; i < inputs.length; i++) {
            inputs[i].classList.remove('--error');
            if (inputs[i].hasAttribute('required')) {
                if (inputs[i].value.length === 0) {
                    errors.push({id: inputs[i].getAttribute('id')});
                    inputs[i].classList.add('--error');
                } else if (Object.keys(country).length === 0) {                   
                    errors.push({ id: 'country' });
                    inputs[i].classList.add('--error');
                
                } else if (inputs[i].hasAttribute('pattern')) {
                    if (checkPattern(inputs[i])) {
                        errors.push({id: inputs[i].getAttribute('id')});
                        inputs[i].classList.add('--error');
                    }
                }
            } else if (inputs[i].value.length > 0 && inputs[i].hasAttribute('pattern')) {
                if (checkPattern(inputs[i])) {
                    errors.push({id: inputs[i].getAttribute('id')});
                    inputs[i].classList.add('--error');
                }
            }
        }

        const email = form?.email?.value;
        const countryCode = form?.spotlight_country?.value;
        if(errors.length === 0 && isInterstitialPopup && email && countryCode) {
            let arguObj = {
                email: email,
                hasAcceptedTermsAndConditions: hasAcceptedTermsAndConditions,
                countryCode: country['countryIsoCode'],
                traitsData: traitsData,
                optinsData: optinsData,
                emailDisclaimerTop: legalText,
                emailOptInStatus: emailOptInStatus,
                traitsSourceValue: SpotlightWaitlistConstants.interstitalSource
            }
            await mixtureFormRegister(arguObj)
                .then((response) => {
                    if (response?.status === 'SUCCESS') {
                        setParticipationId(response['participationId'])
                        setIsLoader(false);
                        setErroneousInputs([]);
                        setIsModalOpen(true);
                        setIsPIIDataRegistrationSuccess(true);
                        if (typeof (window.dataLayer) !== 'undefined') {
                            interestitialEventPush('event_crm_action', 'event_profile_email_signup', 'interstitial_signup');
                            interestitialEventPush(CampaignIframeOverlayConstants?.eventCategory, CampaignIframeOverlayConstants?.questionSurveyAction, CampaignIframeOverlayConstants?.questionSurveyLabel);
                        }
                    } else {
                        setIsSubmitting(false);
                        setIsLoader(false);
                        return response?.json();
                    }
                }).then((response) => {
                    if (response && response.status && response.code && response.code === 'ALREADY_PARTICIPATED') {
                        setServerSideErrorMessage(emailExceedLimitLabel);                        
                    }                    
                }).catch((error) => {
                    console.log('error log:', error);
                    setIsSubmitting(false);
                    setIsLoader(false);
                });
            setIsSubmitting(false);
        } else {
            setErroneousInputs([...errors]);
            setIsSubmitting(false);
            setIsLoader(false)
        }
    }

    const readUserDetails = ()=> {
        return JSON.parse(sessionStorage.getItem(CampaignIframeOverlayConstants.interestitialUserDetail));
    }

    const populateCountryListFromCampaignData = () => {
         let filteredCountryListResult = [];
         // compare countries from the campaign with the one in contentful
         // only add the countries present in the campgain in the list for the dropdown
         for (let i = 0; i < countrySelectList.length; ++i) {
             for (let y = 0; y < countryDropdownList.length; ++y) {
                 let contentfulCountryCode = countrySelectList[i]?.fields?.countryCode;
                 let campaignCountryCode = countryDropdownList[y]?.countryCode;
                 let campaignCountryId = countryDropdownList[y]?.countryId;
                 if (contentfulCountryCode && campaignCountryCode
                         && (contentfulCountryCode.toUpperCase() === campaignCountryCode.toUpperCase())) {

                     const isoCode = countryDropdownList[y]?.isoCode.length > 2 ? countryDropdownList[y]?.isoCode : countryDropdownList[y]?.countryCode;

                     let countryObject = {
                         label: countrySelectList[i]?.fields?.name,
                         countryIsoCode: isoCode,
                         countryId: campaignCountryId,
                         locale: countrySelectList[i]?.fields?.locale
                     };
                     filteredCountryListResult.push(countryObject);
                 }
             }
         }
         // set countryList for dropdown
         setCountryList(filteredCountryListResult);
    };

    useEffect(() => {
        setTimeout(() => {
            if (isInterstitialPopup && !localStorage.getItem(CampaignIframeOverlayConstants.userDataCollectedIntersistialPopUp) && !getCookieValue(CampaignIframeOverlayConstants.disableIntersistialPopUpCookie)) {
                setIsInterstitialModalOpen(true);
                const date = new Date();
                date.setHours(parseInt(expiryHours)); // for one day validaity
                const expires = `expires=${date.toUTCString()}`;
                document.cookie = `${CampaignIframeOverlayConstants.disableIntersistialPopUpCookie}=ok; ${expires}; path=/`;
                setcampaignOverlayPopUp(true);
                sessionStorage.removeItem(CampaignIframeOverlayConstants.interestitialUserDetail);
                interestitialEventPush(
                    CampaignIframeOverlayConstants?.eventCategory,
                    CampaignIframeOverlayConstants?.startSurveyEvent,
                    CampaignIframeOverlayConstants?.eventLabel);
            } else {
                setIsInterstitialModalOpen(false);
            }
        }, 5000);
    }, []);

    useEffect(() => {
        !getCookieValue(CampaignIframeOverlayConstants.disableIntersistialPopUpCookie) && mixtureGraphQL().then((response) => {
            let traitsObj = filterMethod(response?.consumerActions, 'trait');
            let optinsObj = filterMethod(response?.consumerActions, 'optIn');
            setTraitsData(traitsObj);
            setOptinsData(optinsObj);
            sessionStorage.setItem('traitsData', customJsonstringify({
                traitsObj: traitsObj,
                optinsObj: optinsObj
            }));
        }).catch((error) => {
            console.error(error);
        })
    }, []);

    useEffect(()=> {
        if(serverSideErrorMessage && isInterstitialPopup && isSecondModalOpen) {
            setIsModalOpen(false);
            const userDetails = readUserDetails();
            setUserData(userDetails);
        }
    }, [serverSideErrorMessage]);

    useEffect(() => {
        const spotlightThankYouGermanySelector = document.getElementsByClassName('thank-you-de');

        // Thank you page
        if(spotlightThankYouGermanySelector.length > 0) {
            setIsThankYouGermany(true);
            // the state change will trigger the post with the current opt in id
            setOptinId();
        } else {
            if (document.querySelector('.js-modal-container')) {
                Modal.setAppElement('.js-modal-container');
            }

            // fetch the campaign data and create the country list from it, and from contentful countries.
            if(countrySelectList) {
                populateCountryListFromCampaignData();
            } else {
                // case for US
                setOptinId()
            }         
        }

    },[]);

    const renderUserDetailForm = () => {
        return (<div
            ref={blockRef} className={''} id={anchorId} >
            {isMedium &&
                <div className={'ob-spotlight-waitlist__mobile-img'}>
                    <Image image={mobileBackgroundAsset} />
                </div>
            }
            <Image image={(backgroundAsset && !isMedium) ? backgroundAsset : mobileBackgroundAsset} customStyles={{ backgroundSize: 'cover !important', backgroundRepeat: 'no-repeat', padding: '0', minHeight: '580px' }}>
                <div className={'ob-spotlight-waitlist__wrapper'}>
                    <Heading className={'ob-spotlight-waitlist__title'}>{title}</Heading>
                    <div className={'ob-spotlight-waitlist__body'}>{body}</div>
                    {serverSideErrorMessage &&
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                        <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__form-error'}>
                            {serverSideErrorMessage}
                        </div>
                    }
                    {(formLabels) &&
                        <form className={'ob-spotlight-waitlist__form'} onSubmit={handleSubmit} noValidate>
                            <ul className={'ob-spotlight-waitlist__form-list'}>
                                {
                                    formLabels.map((formLabel, formLabelIndex) => (
                                        <li className={'ob-spotlight-waitlist__form-list-item'} key={'formLabel-' + formLabelIndex}>
                                            {(
                                                <label htmlFor={formLabel?.fields?.id} className={'ob-spotlight-waitlist__form-list-item-label'}><span>{formLabel.fields.label.fields.text}</span>
                                                <input
                                                    type={formLabel.fields.type}
                                                    pattern={formLabel?.fields?.validation?.fields?.pattern}
                                                    required={formLabel?.fields?.validation?.fields?.required}
                                                    id={formLabel?.fields?.id}
                                                    name={formLabel?.fields?.id}
                                                    maxLength="100"
                                                    onChange={() => clearErrorStyle(formLabel?.fields?.id)}
                                                    className={'ob-spotlight-waitlist__form-input'}                                                   
                                                    placeholder={formLabel.fields.label.fields.text} 
                                                    autoComplete={formLabel.fields.type}  /></label>
                                            )}

                                            {formLabel.fields.fieldMention &&
                                                <p className={'ob-spotlight-waitlist__form-mention'}>
                                                    {formLabel.fields.fieldMention}
                                                </p>
                                            }

                                            {erroneousInputs.length > 0 &&
                                                erroneousInputs.map((error, index) => {
                                                    return error.id === formLabel.fields.id && formLabel?.fields?.validation?.fields?.errorMessage &&
                                                        <p key={index} className={'ob-spotlight-waitlist__form-error'}>
                                                            {formLabel.fields.validation.fields.errorMessage}
                                                        </p>
                                                })
                                            }
                                        </li>
                                    ))
                                }                            
                              { (countrySelectLabel && countryList && countryList.length > 0) &&
                                        <li className={'ob-spotlight-waitlist__form-list-item'}>
                                     
                                            <div className={'ob-spotlight-waitlist__form-select-container'}>
                                                <select
                                                    required={true}
                                                    id={'spotlight_country'}
                                                    name={'spotlight_country'}
                                                    aria-label={countrySelectLabel}
                                                    className={'ob-spotlight-waitlist__form-input --select'}>
                                                    { countrySelectLabel &&
                                                    <option selected disabled>{countrySelectLabel}</option>
                                                    }

                                                    {
                                                        countryList.map((selectOption, selectOptionIndex) => (
                                                            <option value={selectOption.countryId} key={'selectOption-' + selectOptionIndex}>{selectOption.label}</option>
                                                        ))
                                                    }
                                                </select>

                                                <Icon name={SpotlightWaitlistConstants.chevronDown} size='1.8' />
                                            </div>

                                            { countrySelectMention &&
                                            <p className={'ob-spotlight-waitlist__form-mention'}>
                                                {countrySelectMention}
                                            </p>
                                            }

                                            { erroneousInputs.length > 0 &&
                                            erroneousInputs.map((error, index) => {
                                                return error.id === 'country' && countrySelectErrorMessage &&
                                                    <p key={index} className={'ob-spotlight-waitlist__form-error'}>
                                                        {countrySelectErrorMessage}
                                                    </p>
                                            })
                                            }
                                        </li>
                                        }
                                <li className={cx('ob-spotlight-waitlist__form-list-item cta-section', { 'margin-cta-section': isError })} >
                                    <Button
                                        type={SpotlightWaitlistConstants.submit}
                                        className={cx('cta-button', { 'cta-button-margin': isError })}
                                        whiteTheme={true}
                                        disabled={isSubmitting}>
                                        {`${ctaLabel.fields.text}`}
                                    </Button>
                                    {callToPreOrderActionLabel &&
                                        <Button onClick={closeModalCampaignOverlay} invertedTheme={true} className={cx('ob-button btn-preorder cta-button', { 'cta-button-margin': isError })}>
                                            {callToPreOrderActionLabel}
                                        </Button>
                                    }
                                    <span>
                                        {isLoader && <img src={SpotlightWaitlistConstants.loader} className="loaderImg" />}
                                    </span>
                                </li>
                            </ul>

                            {legalText &&
                                <div className={'ob-spotlight-waitlist__legal-text ob-spotlight-waitlist__legal-text-not-US'}
                                    dangerouslySetInnerHTML={{ __html: legalText }}></div>
                            }
                        </form>
                    }
                </div>
            </Image>
        </div>);
    }

    const handleTopicSelection = event => {
        const checkbox = event.currentTarget;
        const topic = checkbox.getAttribute('value');
        const isChecked  = checkbox.checked;
        let topics = [...selectedTopics];
        if (isChecked && !selectedTopics.includes(topic)) {
            topics.push(topic)
        } else {
            topics = topics.filter(elem => (elem !== topic))
        }
        setSelectedTopics(topics);
    };

    const closeSurveyModal = () => {
        setIsInterstitialModalOpen(false);
        setIsModalOpen(false);
    };

    const postTopicInformations = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setIsLoader(true);
        let arguObj = {
            traitsValue: selectedTopics,
            participationID: participationID
        }
        await updateTraitsMixtureFormRegister(arguObj)
            .then((response) => {
                if (response?.status === 'Success') {
                    setIsLoader(false);
                    setErroneousInputs([]);
                    interestitialSurveyResponseEventPush(selectedTopics);
                    closeInterestitialSurveyResponseEventPush(true);
                    closeSurveyModal();
                } else {
                    setIsSubmitting(false);
                    setIsLoader(false);
                    return response?.json();
                }
            }).then((response) => {
                if (response && response.status && response.code && response.code === 'ALREADY_PARTICIPATED') {
                    setServerSideErrorMessage(emailExceedLimitLabel);                        
                }                    
            }).catch((error) => {
                console.log('error log:', error);
                setIsSubmitting(false);
                setIsLoader(false);
            });
    };

    const renderUserIntrestForm = () => {
        return (<div ref={blockRef} className={''} id={anchorId} >
            <div className={'ob-spotlight-survey__wrapper'}>
                <Heading className={'ob-spotlight-survey__title'}>{thankYouTitleLabel}</Heading>
                <div className={'ob-spotlight-survey__body'}>{thankYouDescriptionLabel}</div>
                <div className={'ob-spotlight-survey__question-body'}>{countrySelectLabel}</div>
                {serverSideErrorMessage &&
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    <div tabIndex='0' className={'ob-focus-error ob-spotlight-survey__form-error'}>
                        {serverSideErrorMessage}
                    </div>
                }
                {rawTopicList &&
                    <form className={'ob-spotlight-survey__form'} onSubmit={postTopicInformations} noValidate>
                        <div className={'ob-spotlight-survey__form-list'}>
                            {
                                rawTopicList.map((topic, topicIndex) => (
                                    <div className={'ob-spotlight-survey__form-list-item'} key={'formLabel-' + topicIndex}>
                                        {isMedium ? <CheckboxInput
                                            name={topic?.fields?.name}
                                            value={topic?.fields?.value}
                                            onChange={handleTopicSelection}
                                            checked={selectedTopics.includes(topic?.fields?.value)}
                                            isRound={true}
                                            label={topic?.fields?.label}
                                            className={'ob-spotlight-survey__form-input'} /> :
                                            <>
                                                <label htmlFor={topic?.fields?.name}>{topic?.fields?.label}</label>
                                                <Image image={topic?.fields?.icon} />
                                                <CheckboxInput
                                                    name={topic?.fields?.name}
                                                    value={topic?.fields?.value}
                                                    onChange={handleTopicSelection}
                                                    checked={selectedTopics.includes(topic?.fields?.value)}
                                                    isRound={true}
                                                    className={'ob-spotlight-survey__form-input'} />
                                            </>}
                                    </div>
                                ))
                            }
                        
                        </div>
                        <div className={'ob-spotlight-survey__cta-section'} >
                            <Button
                                type={SpotlightWaitlistConstants.submit}
                                whiteTheme={true}
                                disabled={isSubmitting}>
                                {`${thankYouCallToAction}`}
                            </Button>
                            <span>
                                    {isLoader && <img src={SpotlightWaitlistConstants.loader} className="loaderImg" />}
                            </span>
                        </div>
                        {modalLabelSecond &&
                            <div className={'ob-spotlight-survey__legal-text ob-spotlight-survey__legal-text-not-US'}
                                dangerouslySetInnerHTML={{ __html: modalLabelSecond }}></div>
                        }
                    </form>
                }
            </div>
        </div>);
    }

    if(isActiveBlock) {
        setLegalModals();
    }

    if (campaignOverlayPopUp && isInterstitialPopup) {
        const customModalStyles = {
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
                background: 'none',
                border: 'none',
                borderRadius: '0',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                fontSize: '1.1rem',
                lineHeight: '2.1rem',
                marginRight: '-50%',
                padding: '0',
                maxWidth: '1100px',
                transform: isMedium ? 'translate(-50%, -28%)' : 'translate(-50%, -50%)',
                width: isMedium ? '100%' :'65%',
                minWidth: isMedium ? '100%' :'65%'
            }
        };

        const customStylesUserIntrest= {
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: isMedium ? 'translate(-50%, -39%)' : 'translate(-50%, -50%)',
                padding: '0',
                width: isMedium ? '100%' : '70%',
                minWidth: isMedium ? '100%' :'70%',
                border: 'none',
                borderRadius: '0',
                maxWidth: '900px',
                maxHeight: isMedium ? '100%' : '590px'
              },
        }
       
        return (
            <div className="ob-campaignoverlay">
                <Modal
                    isOpen={(interstitialModalOpen || isSecondModalOpen)} 
                    contentLabel={anchorId} 
                    aria={{ modal: true }} 
                    onRequestClose={closeModal} closeTimeoutMS={250} style={isSecondModalOpen ? customStylesUserIntrest : customModalStyles}>
                    <div className='ob-campaignoverlay-modal'>
                        <button aria-label={closeModalLabel} onClick={closeModal} className={'ob-spotlight-waitlist__close-modal'} type='button'>
                            <Icon name={SpotlightWaitlistConstants.close} size='1.4' color={Constants.white} />
                        </button>
                        {
                            isSecondModalOpen ? renderUserIntrestForm() : renderUserDetailForm()
                        }
                    </div>
                </Modal>
            </div>
        )
    } else if(!isInterstitialPopup && !campaignOverlayPopUp) {
    return (
        <Transition in={index === undefined} timeout={duration}>
            {() => (
                <div
                    ref={blockRef}
                    className={`ob-spotlightContentBlock ob-spotlight-waitlist js-modal-container ${classNames}`}
                    id={anchorId}
                    data-isactiveblock={isActiveBlock} >
                    { (isMedium && !isThankYouGermany) &&
                    <div className={'ob-spotlight-waitlist__mobile-img'}>
                        <Image image={mobileBackgroundAsset ? mobileBackgroundAsset : undefined} />
                    </div>
                    }
                    <Image image={(backgroundAsset && !isMedium) ? backgroundAsset : (isThankYouGermany ? mobileBackgroundAsset : undefined)} customStyles={{backgroundSize: 'cover', backgroundPositionY: '-15px', backgroundRepeat: 'no-repeat'}}>
                        <div className={'ob-spotlight-waitlist__wrapper'}>
                            <Heading className={'ob-spotlight-waitlist__title'}>{title}</Heading>
                            <div className={'ob-spotlight-waitlist__body'}>{body}</div>

                            {(isThankYouGermany && (thankYouCallToAction && thankYouCallToActionLink)) &&
                                <ObLink href={thankYouCallToActionLink} className="white ob-spotlight-waitlist__link-de" icon="chevronRight" roundedIcon="blue">
                                    <Icon name="chevronRight" roundedIcon="blue" />
                                    {thankYouCallToAction}
                                </ObLink>
                            }

                            {   serverSideErrorMessage &&
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__form-error'}>
                                {serverSideErrorMessage}
                            </div>
                            }

                            { erroneousInputs.length > 0 &&
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__form-error'}>
                                <Label label={numberOfErrorsLabel} tokens={{numErrors: erroneousInputs.length, plural: erroneousInputs.length > 1 ? 's': ''}} />
                            </div>
                            }

                            { (isActiveBlock && formLabels) &&
                                <form className={'ob-spotlight-waitlist__form'} onSubmit={handleSubmit} noValidate>

                                    <ul className={'ob-spotlight-waitlist__form-list'}>
                                        { (countrySelectLabel && countryList && countryList.length > 0) &&
                                        <li className={'ob-spotlight-waitlist__form-list-item'}>
                                            <label htmlFor={'spotlight_country'} className={'ob-spotlight-waitlist__form-label'}>{countrySelectLabel}</label>

                                            <div className={'ob-spotlight-waitlist__form-select-container'}>
                                                <select
                                                    required={true}
                                                    id={'spotlight_country'}
                                                    name={'spotlight_country'}
                                                    aria-label={countrySelectListDefaultOption}
                                                    className={'ob-spotlight-waitlist__form-input --select'}>
                                                    { countrySelectListDefaultOption &&
                                                    <option value="" defaultValue>{countrySelectListDefaultOption}</option>
                                                    }

                                                    {
                                                        countryList.map((selectOption, selectOptionIndex) => (
                                                            <option value={selectOption.countryId} key={'selectOption-' + selectOptionIndex}>{selectOption.label}</option>
                                                        ))
                                                    }
                                                </select>

                                                <Icon name={SpotlightWaitlistConstants.chevronDown} size='1.8' />
                                            </div>

                                            { countrySelectMention &&
                                            <p className={'ob-spotlight-waitlist__form-mention'}>
                                                {countrySelectMention}
                                            </p>
                                            }

                                            { erroneousInputs.length > 0 &&
                                            erroneousInputs.map((error, index) => {
                                                return error.id === 'country' && countrySelectErrorMessage &&
                                                    <p key={index} className={'ob-spotlight-waitlist__form-error'}>
                                                        {countrySelectErrorMessage}
                                                    </p>
                                            })
                                            }
                                        </li>
                                        }

                                        {
                                            formLabels.map((formLabel, formLabelIndex) => (
                                                <li className={'ob-spotlight-waitlist__form-list-item'} key={'formLabel-' + formLabelIndex}>
                                                    <label htmlFor={formLabel.fields.id} className={'ob-spotlight-waitlist__form-label'}>{formLabel.fields.label.fields.text}</label>

                                                    {formLabel.fields.contentType === 'formSelect' ? (
                                                        <div className={'ob-spotlight-waitlist__form-select-container'}>
                                                            <select
                                                                required={formLabel?.fields?.validation?.fields?.required}
                                                                id={formLabel.fields.id}
                                                                name={formLabel.fields.id}
                                                                aria-label={formLabel.fields.defaultValue}
                                                                className={'ob-spotlight-waitlist__form-input --select'}>
                                                                {formLabel.fields.defaultValue &&
                                                                <option value="" defaultValue>{formLabel.fields.defaultValue}</option>
                                                                }

                                                                {
                                                                    formLabel.fields.options.map((selectOption, selectOptionIndex) => (
                                                                        <option value={selectOption.fields.value} key={'selectOption-' + selectOptionIndex}>{selectOption.fields.label}</option>
                                                                    ))
                                                                }
                                                            </select>

                                                            <Icon name={SpotlightWaitlistConstants.chevronDown} size='1.8' />
                                                        </div>
                                                    ) :(
                                                        <input aria-label={formLabel.fields.id}
                                                            type={formLabel.fields.type}
                                                            pattern={formLabel?.fields?.validation?.fields?.pattern}
                                                            required={formLabel?.fields?.validation?.fields?.required}
                                                            id={formLabel.fields.id}
                                                            name={formLabel.fields.id}
                                                            maxLength="100"
                                                            className={'ob-spotlight-waitlist__form-input'}
                                                            autoComplete={`${SpotlightWaitlistConstants.given} ${formLabel.fields.id}`}/>
                                                    )}

                                                    {formLabel.fields.fieldMention &&
                                                    <p className={'ob-spotlight-waitlist__form-mention'}>
                                                        {formLabel.fields.fieldMention}
                                                    </p>
                                                    }

                                                    { erroneousInputs.length > 0 &&
                                                    erroneousInputs.map((error, index) => {
                                                        return error.id === formLabel.fields.id && formLabel?.fields?.validation?.fields?.errorMessage &&
                                                            <p key={index} className={'ob-spotlight-waitlist__form-error'}>
                                                                {formLabel.fields.validation.fields.errorMessage}
                                                            </p>
                                                    })
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    { (process.env.MARKET != 'en-us') &&
                                        <div className={'ob-spotlight-waitlist__legal-text ob-spotlight-waitlist__legal-text-not-US'}
                                        dangerouslySetInnerHTML={{__html: legalText}}></div>
                                    }
                                    <button aria-label={ctaLabel.fields.text}
                                        type={SpotlightWaitlistConstants.submit}
                                        className={`ob-button ${process.env.MARKET != 'en-us' ? ' not-us' : ''}`}
                                        disabled={isSubmitting}>
                                        {ctaLabel.fields.text}
                                        { isSubmitting &&
                                        <span className={'ob-button__spinner'}></span>
                                        }
                                    </button>
                                </form>
                            }

                            { callToPreOrderActionLink && callToPreOrderActionLabel &&
                                <ObLink  isExternalLink={true} href={callToPreOrderActionLink} className="ob-button btn-preorder not-us ">
                                    <span className="span-preorder">{`${callToPreOrderActionLabel}`}</span>
                                </ObLink>
                            }

                            { (process.env.MARKET == 'en-us') &&
                                <div className={'ob-spotlight-waitlist__legal-text'} dangerouslySetInnerHTML={{__html: legalText}}></div>
                            }

                        </div>
                    </Image>
                    <Modal
                        isOpen={isSecondModalOpen}
                        onRequestClose={closeModal}
                        closeTimeoutMS={250}
                        style={customStyles}
                        ref={modalRef}
                        className={'ob-thank-you-modal'}
                    >
                        <div className={'ob-spotlight-waitlist__modal-container'}>
                            {!isMedium &&
                                <div className={'ob-spotlight-waitlist__modal-content'}>
                                    <Image image={thankYouBackgroundAsset} />
                                </div>
                            }
                            <div className={'ob-spotlight-waitlist__modal-text-container ob-spotlight-waitlist__modal-content'}>

                            {(!hasAcceptedTermsAndConditions && process.env.MARKET == 'de-de') ? (
                                    <div>
                                        <Heading>{alternateThankYouTitleLabel}</Heading>
                                        <p className={'ob-spotlight-waitlist__modal-text-content'}>{alternateThankYouDescriptionLabel}</p>
                                    </div>
                                ) : (
                                    <div>
                                        <Heading>{thankYouTitleLabel}</Heading>
                                        <p className={'ob-spotlight-waitlist__modal-text-content'}>{thankYouDescriptionLabel}</p>
                                    </div>
                                )
                            }

                            {(thankYouCallToAction && thankYouCallToActionLink) &&
                                <p className={'ob-spotlight-waitlist__modal-cta-content'}>
                                    <a className={'ob-spotlight-waitlist__modal-cta'}
                                        href={thankYouCallToActionLink}>
                                        {thankYouCallToActionIconName &&
                                        <Icon name={thankYouCallToActionIconName}
                                            size={'1.4'}></Icon>
                                        }
                                        {thankYouCallToAction}
                                    </a>
                                </p>
                            }


                            </div>
                            {isMedium &&
                            <div>
                                <Image image={thankYouBackgroundAsset} />
                            </div>
                            }
                            <button aria-label={closeModalLabel} onClick={closeModal} className={'ob-spotlight-waitlist__close-modal'} type='button'>
                                <Icon name={SpotlightWaitlistConstants.close} size='2.4' />
                            </button>
                        </div>
                    </Modal>

                    {(modalLabelFirst || modalLabelSecond) &&
                        <Modal
                            isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
                            style={customStylesLegal}
                            onRequestClose={closeFirstLegalModal}
                            closeTimeoutMS={250}
                            overlayClassName={'event_button_click'}
                            ref={firstLegalModalRef}
                        >
                            <div className='ob-spotlight-waitlist__modal-container-legal'>

                                { isFirstLegalModalOpen && modalLabelFirst &&
                                <p dangerouslySetInnerHTML={{__html: modalLabelFirst}}></p>
                                }

                                { isSecondLegalModalOpen && modalLabelSecond &&
                                <p dangerouslySetInnerHTML={{__html: modalLabelSecond}}></p>
                                }

                                <button className='event_close_window ob-modal-close'
                                    onClick={closeFirstLegalModal}
                                    aria-label={closeModalLabel} type='button'>
                                    <Icon name='close' size='2.4' color='#000' />
                                </button>

                            </div>
                        </Modal>
                    }
                </div>
            )}
        </Transition>
    )
    } else {
        return null;
    }
}

export default mediaQueryHOC(InterstitialLayout);

InterstitialLayout.propTypes = {
    document: PropTypes.object,
    isMedium: PropTypes.bool,
    index: PropTypes.any
};